<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @cancel="onClose" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :label-col="labelCol" :wrapper-col="wrapperCol" :model="form"  :rules="rules">
      <a-form-model-item prop="imGroupId" >
        <span slot="label" >
          <a-tooltip>
            {{$t('直播.腾讯IM群ID(发起直播单独创建一个群)')}}<template slot="title">{{$t('直播.腾讯IM群ID(发起直播单独创建一个群)')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.imGroupId" :maxLength="255" :placeholder="$t('通用.输入.请输入')+$t('直播.腾讯IM群ID(发起直播单独创建一个群)')" />
      </a-form-model-item>
      <a-form-model-item prop="userId" >
        <span slot="label" >
          <a-tooltip>
            {{$t('直播.直播用户id')}}<template slot="title">{{$t('直播.直播用户id')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.userId"  :placeholder="$t('通用.输入.请输入')+$t('直播.直播用户id')" />
      </a-form-model-item>
      <a-form-model-item prop="shopId" >
        <span slot="label" >
          <a-tooltip>
            {{$t('直播.直播商户id')}}<template slot="title">{{$t('直播.直播商户id')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.shopId"  :placeholder="$t('通用.输入.请输入')+$t('直播.直播商户id')" />
      </a-form-model-item>
      <a-form-model-item prop="shopName" >
        <span slot="label" >
          <a-tooltip>
            {{$t('直播.直播人昵称')}}<template slot="title">{{$t('直播.直播人昵称')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.shopName" :maxLength="255" :placeholder="$t('通用.输入.请输入')+$t('直播.直播人昵称')" />
      </a-form-model-item>
      <a-form-model-item prop="shopLogo" >
        <span slot="label" >
          <a-tooltip>
            {{$t('直播.直播人头像')}}<template slot="title">{{$t('直播.直播人头像')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.shopLogo" :maxLength="255" :placeholder="$t('通用.输入.请输入')+$t('直播.直播人头像')" />
      </a-form-model-item>
      <a-form-model-item prop="thumb" >
        <span slot="label" >
          <a-tooltip>
            {{$t('直播.直播封面')}}<template slot="title">{{$t('直播.直播封面')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.thumb" :maxLength="255" :placeholder="$t('通用.输入.请输入')+$t('直播.直播封面')" />
      </a-form-model-item>
      <a-form-model-item prop="theme" >
        <span slot="label" >
          <a-tooltip>
            {{$t('直播.直播主题')}}<template slot="title">{{$t('直播.直播主题')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.theme" :maxLength="255" :placeholder="$t('通用.输入.请输入')+$t('直播.直播主题')" />
      </a-form-model-item>
      <a-form-model-item prop="intro" >
        <span slot="label" >
          <a-tooltip>
            {{$t('直播.直播主题介绍')}}<template slot="title">{{$t('直播.直播主题介绍')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.intro" :placeholder="$t('通用.输入.请输入')" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item prop="liveName" >
        <span slot="label" >
          <a-tooltip>
            {{$t('直播.直播间名称(推流名称)')}}<template slot="title">{{$t('直播.直播间名称(推流名称)')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.liveName" :maxLength="255" :placeholder="$t('通用.输入.请输入')+$t('直播.直播间名称(推流名称)')" />
      </a-form-model-item>
      <a-form-model-item prop="openTime" >
        <span slot="label" >
          <a-tooltip>
            {{$t('直播.直播开启时间')}}<template slot="title">{{$t('直播.直播开启时间')}}</template>
          </a-tooltip>
        </span>
        <a-date-picker style="width: 100%" v-model="form.openTime" format="YYYY-MM-DD HH:mm:ss" allow-clear/>
      </a-form-model-item>
      <a-form-model-item prop="closeTime" >
        <span slot="label" >
          <a-tooltip>
            {{$t('直播.直播关闭时间(前端点击关播时间,或者定时任务扫描到没有推流了)')}}<template slot="title">{{$t('直播.直播关闭时间(前端点击关播时间,或者定时任务扫描到没有推流了)')}}</template>
          </a-tooltip>
        </span>
        <a-date-picker style="width: 100%" v-model="form.closeTime" format="YYYY-MM-DD HH:mm:ss" allow-clear/>
      </a-form-model-item>
      <a-form-model-item prop="expiredTime" >
        <span slot="label" >
          <a-tooltip>
            {{$t('直播.推流过期时间')}}<template slot="title">{{$t('直播.推流过期时间')}}</template>
          </a-tooltip>
        </span>
        <a-date-picker style="width: 100%" v-model="form.expiredTime" format="YYYY-MM-DD HH:mm:ss" allow-clear/>
      </a-form-model-item>
      <a-form-model-item prop="effective" >
        <span slot="label" >
          <a-tooltip>
            {{$t('直播.直播有效时长(单位:小时)')}}<template slot="title">{{$t('直播.直播有效时长(单位:小时)')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.effective"  :placeholder="$t('通用.输入.请输入')+$t('直播.直播有效时长(单位:小时)')" />
      </a-form-model-item>
      <a-form-model-item prop="duration" >
        <span slot="label" >
          <a-tooltip>
            {{$t('直播.直播时长')}}<template slot="title">{{$t('直播.直播时长')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.duration"  :placeholder="$t('通用.输入.请输入')+$t('直播.直播时长')" />
      </a-form-model-item>
      <a-form-model-item prop="pushUrl" >
        <span slot="label" >
          <a-tooltip>
            {{$t('直播.推流地址')}}<template slot="title">{{$t('直播.推流地址')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.pushUrl" :placeholder="$t('通用.输入.请输入')" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item prop="pullUrl" >
        <span slot="label" >
          <a-tooltip>
            {{$t('直播.拉流地址')}}<template slot="title">{{$t('直播.拉流地址')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.pullUrl" :placeholder="$t('通用.输入.请输入')" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item prop="chatNum" >
        <span slot="label" >
          <a-tooltip>
            {{$t('直播.直播互动人数(峰值)')}}<template slot="title">{{$t('直播.直播互动人数(峰值)')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.chatNum"  :placeholder="$t('通用.输入.请输入')+$t('直播.直播互动人数(峰值)')" />
      </a-form-model-item>
      <a-form-model-item prop="totalMoney" >
        <span slot="label" >
          <a-tooltip>
            {{$t('直播.直播累计收益 ')}}<template slot="title">{{$t('直播.直播累计收益 ')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.totalMoney"  :placeholder="$t('通用.输入.请输入')+$t('直播.直播累计收益 ')" />
      </a-form-model-item>
      <a-form-model-item prop="status" >
        <span slot="label" >
          <a-tooltip>
            {{$t('直播.直播状态 1:推流地址生成(未开播) 2:已开播 3:直播结束')}}<template slot="title">{{$t('直播.直播状态 1:推流地址生成(未开播) 2:已开播 3:直播结束')}}</template>
          </a-tooltip>
        </span>
      </a-form-model-item>
      <a-form-model-item prop="cutInfo" >
        <span slot="label" >
          <a-tooltip>
            {{$t('直播.断流信息(直播断流总信息)')}}<template slot="title">{{$t('直播.断流信息(直播断流总信息)')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.cutInfo" :placeholder="$t('通用.输入.请输入')" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item prop="isErrorClose" >
        <span slot="label" >
          <a-tooltip>
            {{$t('直播.是否直播流异常,1,异常 0,正常,腾讯云直播流异常时写入')}}<template slot="title">{{$t('直播.是否直播流异常,1,异常 0,正常,腾讯云直播流异常时写入')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.isErrorClose"  :placeholder="$t('通用.输入.请输入')+$t('直播.是否直播流异常,1,异常 0,正常,腾讯云直播流异常时写入')" />
      </a-form-model-item>
      <a-form-model-item prop="errorCloseTime" >
        <span slot="label" >
          <a-tooltip>
            {{$t('直播.异常时间')}}<template slot="title">{{$t('直播.异常时间')}}</template>
          </a-tooltip>
        </span>
        <a-date-picker style="width: 100%" v-model="form.errorCloseTime" format="YYYY-MM-DD HH:mm:ss" allow-clear/>
      </a-form-model-item>
      <a-form-model-item prop="classifyId" >
        <span slot="label" >
          <a-tooltip>
            {{$t('直播.直播分类,live_live_classify')}}<template slot="title">{{$t('直播.直播分类,live_live_classify')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.classifyId" :maxLength="32" :placeholder="$t('通用.输入.请输入')+$t('直播.直播分类,live_live_classify')" />
      </a-form-model-item>
      <a-form-model-item prop="liveType" >
        <span slot="label" >
          <a-tooltip>
            {{$t('直播.直播类型 (1:语音直播,2:视频直播)')}}<template slot="title">{{$t('直播.直播类型 (1:语音直播,2:视频直播)')}}</template>
          </a-tooltip>
        </span>
      </a-form-model-item>
      <a-form-model-item prop="liveAddress" >
        <span slot="label" >
          <a-tooltip>
            {{$t('直播.直播地址')}}<template slot="title">{{$t('直播.直播地址')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.liveAddress" :maxLength="100" :placeholder="$t('通用.输入.请输入')+$t('直播.直播地址')" />
      </a-form-model-item>
      <a-form-model-item prop="liveCityName" >
        <span slot="label" >
          <a-tooltip>
            {{$t('直播.直播城市')}}<template slot="title">{{$t('直播.直播城市')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.liveCityName" :maxLength="32" :placeholder="$t('通用.输入.请输入')+$t('直播.直播城市')" />
      </a-form-model-item>
      <a-form-model-item prop="redPacketExpiredTime" >
        <span slot="label" >
          <a-tooltip>
            {{$t('直播.红包过期时间')}}<template slot="title">{{$t('直播.红包过期时间')}}</template>
          </a-tooltip>
        </span>
        <a-date-picker style="width: 100%" v-model="form.redPacketExpiredTime" format="YYYY-MM-DD HH:mm:ss" allow-clear/>
      </a-form-model-item>
      <a-form-model-item prop="redPacketBeginTime" >
        <span slot="label" >
          <a-tooltip>
            {{$t('直播.红包开始领取时间')}}<template slot="title">{{$t('直播.红包开始领取时间')}}</template>
          </a-tooltip>
        </span>
        <a-date-picker style="width: 100%" v-model="form.redPacketBeginTime" format="YYYY-MM-DD HH:mm:ss" allow-clear/>
      </a-form-model-item>
      <a-form-model-item prop="remark" >
        <span slot="label" >
          <a-tooltip>
            {{$t('直播.备注')}}<template slot="title">{{$t('直播.备注')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.remark" :placeholder="$t('通用.输入.请输入')" type="textarea" allow-clear />
      </a-form-model-item>
      <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
        <img alt="example" style="width: 100%" :src="previewUrl" />
      </a-modal>
      <a-modal :visible="previewVideoVisible" :footer="null" @cancel="previewVideoCancel">
        <video style="width: 100%" :src="previewUrl" ref="myVideo"
               :poster="previewUrl + '?x-oss-process=video/snapshot,t_0,f_jpg'" :controls="true"></video>
      </a-modal>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            {{$t('通用.按钮.保存')}}
          </a-button>
          <a-button type="dashed" @click="cancel">
            {{$t('通用.按钮.取消')}}
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getLive, addLive, updateLive } from '@/api/live/live'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";
import {generateFilePath, uploadObject} from "@/api/tool/oss";


export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    CustomDictTag,
  },
  data () {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      submitLoading: false,
      formTitle: '',
      previewImageVisible: false,
      previewUrl: '',
      previewVideoVisible: false,
      loading: '',
      // 表单参数
      form: {
        id: null,

        imGroupId: null,

        userId: null,

        shopId: null,

        shopName: null,

        shopLogo: null,

        thumb: null,

        theme: null,

        intro: null,

        liveName: null,

        openTime: null,

        closeTime: null,

        expiredTime: null,

        effective: null,

        duration: null,

        pushUrl: null,

        pullUrl: null,

        chatNum: null,

        totalMoney: null,

        status: 0,

        cutInfo: null,

        isErrorClose: null,

        errorCloseTime: null,

        classifyId: null,

        liveType: null,

        liveAddress: null,

        liveCityName: null,

        redPacketExpiredTime: null,

        redPacketBeginTime: null,

        createTime: null,

        remark: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        imGroupId: [
          { required: true, message: this.$t('直播.腾讯IM群ID(发起直播单独创建一个群)')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        theme: [
          { required: true, message: this.$t('直播.直播主题')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        isErrorClose: [
          { required: true, message: this.$t('直播.是否直播流异常,1,异常 0,正常,腾讯云直播流异常时写入')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
      this.reset()
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    previewVideoCancel() {
      this.previewVideoVisible = false;
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        imGroupId: null,
        userId: null,
        shopId: null,
        shopName: null,
        shopLogo: null,
        thumb: null,
        theme: null,
        intro: null,
        liveName: null,
        openTime: null,
        closeTime: null,
        expiredTime: null,
        effective: null,
        duration: null,
        pushUrl: null,
        pullUrl: null,
        chatNum: null,
        totalMoney: null,
        status: 0,
        cutInfo: null,
        isErrorClose: null,
        errorCloseTime: null,
        classifyId: null,
        liveType: null,
        liveAddress: null,
        liveCityName: null,
        redPacketExpiredTime: null,
        redPacketBeginTime: null,
        createTime: null,
        remark: null
      }

    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = this.$t('通用.文本.添加')
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getLive({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = this.$t('通用.文本.修改')
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          let message = '';
          if (this.form.id !== undefined && this.form.id !== null) {
            message = this.$t('通用.文本.修改成功')
            updateLive(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            message = this.$t('通用.文本.修改成功')
            addLive(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
